*{
  margin : 0;
  padding : 0;
  box-sizing: border-box;
}

body{
  height: 100vh;
  background : linear-gradient(to right,#4daf54,#3d8888);
}
