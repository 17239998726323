$primary-color: #4daf54;

.card{
  margin: 150px auto;
  width: 350px;
  height: 450px;
  background-color: #272d40;
  border-radius: 20px;
  padding: 20px;
  color: white;
  position: relative;

  .card__heart, .card__cart{
    font-size: 25px;
    position: absolute;
    left: 20px;
    top : 15px;
    z-index: 5;
  }
  .card__cart{
    left:unset;
    right: 20px;
  }
  .card__image img{
    width: 100%;
    height: 100%;
    object-fit: cover;

  }

  .card__title, .card__price{
    font-size: 25px;
    font-weight: bold;
    text-align: center;

  }
  .card__title{
    color: $primary-color;
  }
  .card__size, .card__color{
    display: flex;
    align-items: center;
    font-size: 20px;
    font-weight: bold;
  }
  .card__size span{
    padding: 0px 5px;
    background-color: #dadada;
    color: black;
    border-radius: 5px;
    margin: 5px;
  }
  .card__color{
    span{
    width: 20px;
    height: 20px;
    border-radius: 50%;
    margin: 5px;
    margin: 10px;
    }
    .card__color-green{
      background: green;
    }
    .card__color-red{
      background: red;
    }
    .card__color-black{
      background: black;
    }

  }
  .card__buttons button{
    background: $primary-color;
    border-radius: 5px;
    border: none;
    outline: none;
    padding: 10px 10px;
    font-weight: bold;
    font-size: 18px;
    color: white;
    margin: 5px;
  }
  .card__size{
    margin-top: 100px;
  }
  .card__size, .card__color , .card__buttons{
      transition: 2s;
      visibility: hidden;
      opacity: 0;

  }
  .card__title, .card__price, .card__image img{
    transition: 1s;
  }
  .card__heart, .card__cart, .card__color span:hover,.card__size span:hover{
    cursor: pointer;
  }
  .card__buttons button:hover{
    cursor: pointer;
    transform: scale(0.9);
    transition: 0.1s;
  }
};


.card:hover {
  .card__image img{
    transition-duration: 2.5s;
    transform: rotateZ(-20deg) translateY(-100px);


  }
  .card__title, .card__price{
    transition: 2.5s;
    transform: translate(0px,-90px);
  }
  .card__size, .card__color , .card__buttons{
      transition: 2.5s;
      visibility:visible;
      transform: translateY(-150px);
      opacity: 1;
  }
}
